import {
  BookingsQueryParams,
  getUrlQueryParamValue,
} from '@wix/bookings-catalog-calendar-viewer-utils/';
import { IWixAPI } from '@wix/yoshi-flow-editor';
import { SERVICES_PAGE_SIZE } from '../../api/SettingsApi';

export const getRequestedPageFromQueryParam = (wixCodeApi: IWixAPI) => {
  const pageNum = Number(
    getUrlQueryParamValue(wixCodeApi, BookingsQueryParams.PAGE),
  );
  return !isNaN(pageNum) ? pageNum : 0;
};

export const calculateNumberOfPages = (totalItems?: number | null) =>
  totalItems ? Math.ceil(totalItems / SERVICES_PAGE_SIZE) : 1;
