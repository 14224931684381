import { queryServices } from '@wix/ambassador-bookings-services-v2-service/http';
import { SortOrder } from '@wix/ambassador-bookings-services-v2-service/types';
import { queryLocations } from '@wix/ambassador-locations-v1-location/http';
import { ServicesCatalogServer } from '@wix/ambassador-services-catalog-server/http';
import { IHttpClient } from '@wix/yoshi-flow-editor';

export const SERVICES_PAGE_SIZE = 100;

const CATALOG_SERVER_URL = '_api/services-catalog';

export class SettingsAPI {
  private catalogServer: ReturnType<typeof ServicesCatalogServer>;

  constructor(private httpClient: IHttpClient) {
    const serverBaseUrl = '/';

    this.catalogServer = ServicesCatalogServer(
      `${serverBaseUrl}${CATALOG_SERVER_URL}`,
    );
  }

  private get authorization() {
    return window.query?.get('instance') || '';
  }

  queryServices(offset = 0) {
    const sort = [
      {
        fieldName: 'name',
        order: SortOrder.ASC,
      },
    ];

    const filter = {
      $or: [{ hidden: false }, { hidden: { $exists: false } }],
    };

    const paging = {
      limit: SERVICES_PAGE_SIZE,
      offset,
    };

    return this.httpClient.request(
      queryServices({
        query: {
          sort,
          filter,
          paging,
        },
      }),
    );
  }

  getBusinessInfo() {
    return this.catalogServer
      .BusinessCatalog()({ Authorization: this.authorization })
      .get({ suppressNotFoundError: false });
  }

  async getBusinessLocations() {
    try {
      const { data: businessLocations } = await this.httpClient.request(
        queryLocations({}),
      );
      return businessLocations.locations || [];
    } catch (e) {
      return [];
    }
  }
}
